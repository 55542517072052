@use 'styles/include-media' as media;

.btn {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: var(--boels-space-10);
  color: var(--boels-color-white);
  cursor: pointer;
  transition: 0.2s;
  padding: calc(var(--boels-space-14) / 2) var(--boels-space-12) var(--boels-space-8);
  font-weight: bold;
  text-decoration: none;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  &:not(.mat-mdc-fab):active {
    box-shadow: unset;

    &::after {
      content: '';
      position: absolute;
      width: 55%;
      aspect-ratio: 1;
      border-radius: 50%;
      background: var(--boels-color-white);
      opacity: 0.18;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.mat-mdc-fab {
    height: 40px;
    width: 40px;

    &:not(.mat-mdc-button-disabled),
    &:not(.mat-mdc-button-disabled):active {
      box-shadow: none;
    }

    &:not(.mat-mdc-button-disabled):focus {
      box-shadow: 0 0 6px 0 var(--bds-semantic-color-default-text);
    }

    .mdc-button__label {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 0;
    }

    img {
      height: 24px;
      width: 24px;
      // white svg
      filter: brightness(0) invert(1);
    }
  }
}

.btn-primary {
  width: 100%;
  background-color: var(--boels-color-primary);
  font-size: var(--boels-font-size-base);

  @include media.breakpoint('>=sm') {
    font-size: var(--boels-font-size-sm);
  }

  @include media.breakpoint('>=md') {
    font-size: inherit;
    padding: calc(var(--boels-space-12) / 2) var(--boels-space-12) var(--boels-space-8);
  }

  @include media.breakpoint('>=lg') {
    padding: var(--boels-space-10) var(--boels-space-20);
  }

  &:hover:not([disabled]) {
    box-shadow: 2px 2px 6px 0 var(--boels-color-gray-600);
    background-color: var(--boels-color-primary-dark);
    border-color: var(--boels-color-primary-dark);
  }

  &:focus {
    border-color: #c34e00;
    box-shadow: 0 0 8px 0 var(--boels-color-primary);
  }

  &[disabled] {
    pointer-events: none;
    background-color: var(--boels-color-gray-300);
    color: var(--boels-color-gray-600);
  }
}

.disabled-btn-primary {
  width: 100%;
  background-color: var(--boels-color-gray-200);
  font-size: var(--boels-font-size-base);
  color: var(--boels-color-gray-600);
  pointer-events: none;

  @include media.breakpoint('>=sm') {
    font-size: var(--boels-font-size-sm);
  }

  @include media.breakpoint('>=md') {
    font-size: inherit;
    padding: calc(var(--boels-space-12) / 2) var(--boels-space-12) var(--boels-space-8);
  }

  @include media.breakpoint('>=lg') {
    padding: var(--boels-space-10) var(--boels-space-20);
  }

  &:hover:not([disabled]) {
    box-shadow: 2px 2px 6px 0 var(--boels-color-gray-600);
    background-color: var(--boels-color-primary-dark);
    border-color: var(--boels-color-primary-dark);
  }
}

.btn-secondary {
  gap: var(--boels-space-8);
  background-color: var(--bds-semantic-color-default-text);
  font-size: inherit;

  @include media.breakpoint('>=lg') {
    padding: var(--boels-space-8) var(--boels-space-20) var(--boels-space-10);
  }

  &.mat-mdc-fab {
    background-color: var(--boels-color-gray-600);

    &:hover {
      background-color: var(--bds-semantic-color-default-text);
    }

    &:not(.mat-mdc-button-disabled):focus {
      background-color: var(--bds-semantic-color-default-text);
    }

    &:not(.mat-mdc-button-disabled)[aria-expanded='true'] {
      background-color: var(--boels-color-primary);
      box-shadow: none;
    }
  }

  img {
    width: var(--boels-space-12);

    @include media.breakpoint('>=lg') {
      width: var(--boels-space-16);
    }
  }

  &:hover:not([disabled]) {
    box-shadow: 2px 2px 6px 0 var(--boels-color-gray-600);
  }

  &:focus {
    box-shadow: 0 0 8px 0 var(--bds-semantic-color-default-text);
  }

  &[disabled] {
    pointer-events: none;
    background-color: var(--boels-color-gray-300);
    color: var(--boels-color-gray-600);
  }
}

.btn-outlined {
  gap: var(--boels-space-8);
  color: var(--bds-semantic-color-default-text);
  border: 1px solid var(--boels-color-gray-600);
  font-size: inherit;
  background-color: transparent;

  &.mat-mdc-fab {
    background-color: transparent;
    color: var(--bds-semantic-color-default-text);
    border: 1px solid var(--boels-color-gray-600);

    &:hover {
      background-color: rgba($black, 0.08);
    }

    &:not(.mat-mdc-button-disabled):focus {
      background-color: rgba($black, 0.12);
      border-color: var(--boels-color-gray-600);
      box-shadow: unset;
    }

    &:not(.mat-mdc-button-disabled)[aria-expanded='true'] {
      background-color: transparent;
      box-shadow: unset;
    }

    img {
      filter: inherit;
    }
  }

  img {
    width: var(--boels-space-12);
  }

  &:hover:not([disabled]) {
    background-color: rgba($gray-800, 0.08);
  }

  &:focus {
    border-color: var(--bds-semantic-color-default-text);
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }
}

.btn-basic,
.btn-text {
  gap: var(--boels-space-8);
  color: var(--bds-semantic-color-default-text);
  font-size: inherit;
  background-color: transparent;

  @include media.breakpoint('>=sm') {
    font-size: var(--boels-font-size-sm);
  }

  @include media.breakpoint('>=md') {
    font-size: inherit;
    padding: calc(var(--boels-space-12) / 2) var(--boels-space-12) var(--boels-space-8);
  }

  @include media.breakpoint('>=lg') {
    padding: var(--boels-space-10) var(--boels-space-20);
  }

  &.mat-mdc-fab {
    background-color: transparent;
    color: var(--bds-semantic-color-default-text);

    &:hover {
      background-color: rgba($black, 0.08);
    }

    &:not(.mat-mdc-button-disabled):focus {
      background-color: rgba($black, 0.12);
      box-shadow: unset;
    }

    &:not(.mat-mdc-button-disabled)[aria-expanded='true'] {
      background-color: transparent;
      box-shadow: unset;
    }

    img {
      filter: inherit;
      @include media.breakpoint('>=lg') {
        width: var(--boels-space-16);
      }
    }
  }

  img {
    width: var(--boels-space-12);
  }

  &:hover:not([disabled]) {
    background-color: rgba($gray-800, 0.08);
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &[disabled] {
    opacity: 0.4;
    pointer-events: none;
  }
}

.btn-small {
  width: auto;
  border-radius: 6px;
  padding: 4px 14px;
}

.btn-mini {
  font-size: var(--boels-font-size-md);
  font-weight: normal;
  width: auto;
  border-radius: 6px;
  padding: 2px 14px;
}

.btn-text {
  font-weight: normal;
  background-color: transparent !important;
  text-decoration: underline;
  padding-inline: 0;
  color: var(--bds-semantic-color-default-text);

  &:hover {
    color: var(--bds-brand-color-primary);
  }
}
